
import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'



const Why = () => {

    const data = useStaticQuery(
        graphql`
          query {
            wipe: file(relativePath: { eq: "wipe.png" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 64) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            relax: file(relativePath: { eq: "relax.png" }) {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 64) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
          }
        `
      )

      const wipeIcon = data.wipe.childImageSharp.fluid;
      const relaxIcon = data.relax.childImageSharp.fluid;
  
    return(
        <section style={{padding: '1rem 0'}} className="bg-light">
             <Container fluid>
                <Row className="text-center">
                    <Col xs={12} md={6} id="trust-col">
                        <Row>
                            <Col xs={12}>
                                <Row className="text-center">
                                    <Col/>
                                    <Col>
                                        <Img fluid={wipeIcon} className="why-icon"></Img>
                                    </Col> 
                                    <Col/>                              
                                </Row>
                            </Col>
                            <Col xs={12}>
                                <Row>
                                    <Col>
                                        <h4 className="text-muted">Trustworthy and reliable cleaning services</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                                            
                    </Col>
                    <Col xs={12} md={6} id="relax-col">  
                    <Row>
                            <Col xs={12}>
                                <Row className="text-center">
                                    <Col/>
                                    <Col>
                                        <Img fluid={relaxIcon} className="why-icon"></Img>
                                    </Col>   
                                    <Col/>                            
                                </Row>
                            </Col>
                            <Col xs={12}>
                                <Row>
                                    <Col>
                                        <h4 className="text-muted">We do the hard jobs, so you can relax</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>                
                           
                    </Col>
                </Row>
            </Container>
        </section>
    )  
  
}



export default Why
