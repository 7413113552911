import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/home/hero"
import Services from "../components/home/services"
import Trust from "../components/home/trust"
import GetInTouch from "../components/get-in-touch"
import AreaCoverage from "../components/area-coverage"
import Why from "../components/home/why"
import Teams from "../components/home/teams"

const IndexPage = () => (
  <Layout>
    <SEO title="Cleaning Services in Chichester" />
    <Hero/>
    <Why/>
    <Services/>
    <Trust/>  
    <AreaCoverage/>
    <Teams/>  
    <GetInTouch/>
    
  </Layout>
)

export default IndexPage
