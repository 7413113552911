
import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'



const Teams = () => {

    const data = useStaticQuery(
        graphql`
          query {
            profilePic: file(relativePath: { eq: "debbie.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 810) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `
      )

      
  
    return(
      <>
        <section style={{padding: '3rem 0'}} className="bg-light">
             <Container>
                <Row style={{paddingBottom: '1rem'}}>
                    <Col>
                        <h3>Our Team Values</h3>
                    </Col>
                </Row>
                <hr style={{border: '1px solid #AED9E0', width: '10%', margin: '0px'}}/>
                <Row style={{paddingTop: '2rem'}}>
                    <Col>
                        <h4>Experienced</h4>
                        <p className="text-justify">Our Teams are highly trained and experienced to work in your premises with a friendly attitude. They know how to work in your home and business with a loyal and caring attitude. This makes them the best and perfect choice as your cleaning service provider.  </p>
                    </Col>
                </Row>
                <Row style={{paddingTop: '2rem'}}>
                    <Col>
                        <h4>Reliable</h4>
                        <p className="text-justify">There are strict procedures in our hiring process to ensure the trustfulness nature of the teams. With reliability comes the trust that you do not have to be worried about the safety and security of your premises. Most of the customers see the team as a member of their family because they trust the person and the maids care for your property as if it were their own.</p>
                    </Col>
                </Row>
                <Row style={{paddingTop: '2rem'}}>
                    <Col>
                        <h4>Professional</h4>
                        <p className="text-justify">Our teams undergo rigorous training on your cleaning needs, whether at home or in the workplace. Once our teams reach your property, they know exactly where to begin the cleaning, how to organise your premises and maintain a high level of professional behavior.</p>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    )  
  
}



export default Teams
