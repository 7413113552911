
import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const Services = () => {

    const data = useStaticQuery(
        graphql`
          query {
            residential: file(relativePath: { eq: "residential-cleaning.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 810) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            commercial: file(relativePath: { eq: "commercial-cleaning.jpg" }) {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 810) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
          }
        `
      )

      const residentialImage = data.residential.childImageSharp.fluid
      const commercialImage = data.commercial.childImageSharp.fluid
  
    return(
        <section id="services-section">
            <Container fluid style={{padding: '0', overflow: 'hidden'}}>           

                <Row className="text-center" style={{width: '100%', margin:'0'}}>
                    <Col xs={12} md={6} id="residential-cleaning-service-col" style={{padding: '1px'}}>
                        <BackgroundImage Tag="div" fluid={residentialImage} style={serviceImages}>
                        <Row className="services-box-padding"></Row>
                        <Row>
                                    <Col></Col>
                                    <Col xs={10} lg={8}>
                                        <div style={{backgroundColor: '#F5F5F5', width:'100%', padding: '10px', opacity: '95%'}}>
                                            <Row>
                                                <Col>
                                                    <h2>Residential</h2>
                                                </Col>
                                            </Row>
                                            <hr style={hrStyle}/>
                                            <Row>
                                                <Col><p>We will provide you with the same cleaners every time so that they will get to know your property in detail. Our cleaners are fully vetted and background–checked and, of course, very experienced. </p></Col>
                                            </Row>
                                            <Row>
                                                <Col><Link to="/residential-cleaning-services"><Button>Find Out More</Button></Link></Col>                               
                                            </Row>
                                            
                                        </div> 
                                    </Col>
                                    <Col></Col>
                                </Row>
                       </BackgroundImage>
                    </Col>
                    <Col xs={12} md={6} id="commercial-cleaning-service-col" style={{padding: '1px'}}>
                        <BackgroundImage Tag="div" fluid={commercialImage} style={serviceImages}>
                        <Row className="services-box-padding"></Row>
                            

                                <Row>
                                    <Col></Col>
                                    <Col xs={10} lg={8}>
                                        <div style={{backgroundColor: '#F5F5F5', width:'100%', padding: '10px', opacity: '95%'}}>
                                            <Row>
                                                <Col>
                                                    <h2>Commercial</h2>
                                                </Col>
                                            </Row>
                                            <hr style={hrStyle}/>
                                            <Row>
                                                <Col><p>Providing the most cost-effective commercial cleaning service possible, delivering exceptional professionalism and expertise with every job. We clean any type and size of building, from restaurants through to multi-storey offices.</p></Col>
                                            </Row>
                                            <Row>
                                                <Col><Link to="/commercial-cleaning-services"><Button>Find Out More</Button></Link></Col>                               
                                            </Row>
                                            
                                        </div> 
                                    </Col>
                                    <Col></Col>
                                </Row>                            
                       </BackgroundImage>
                    </Col>
                </Row>
            </Container>
        </section>
    )
   
  
}

const serviceImages = {

    minHeight: '75vh',
    backgroundSize: 'cover'

}

const hrStyle = {
    border: '1px solid #AED9E0', 
    width: '50%'
}



export default Services
