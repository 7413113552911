
import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"

const AreaCoverage = () => {
  
    return(
        <section className="areas-we-cover">
            <Container>
                <Row className="text-center">
                    <Col lg={6} >
                       <h4>Areas We Cover</h4>
                       <hr/>
                       <Row>
                           <Col>
                                <p>Chichester</p>
                                <p>Bosham</p>                          
                                <p>Lavant</p>
                                <p>Emsworth</p>
                           </Col>    
                           <Col>
                                <p>Selsey</p>
                                <p>Bognor</p>
                                <p>Birdham</p>
                                <p>Chilgrove</p>
                           </Col>  
                        <Col><p>Wittering</p><p>Singleton</p><p>Littlehampton</p><p>Funtington</p></Col>
                       </Row>
                    </Col>
                    <Col lg={6} style={{padding: '0'}}>
                    <iframe id="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d322613.0447481819!2d-1.0185106678923848!3d50.82348743115595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x629846404714096!2sBright%20and%20Clean!5e0!3m2!1sen!2sae!4v1606226893547!5m2!1sen!2sae" width="100%" height="100%" frameBorder="0" style={{border:'0'}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                    
                    </Col>
                </Row>
            </Container>
        </section>
    )
   
  
}



export default AreaCoverage
