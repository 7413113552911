
import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'



const Trust = () => {

    const data = useStaticQuery(
        graphql`
          query {
            profilePic: file(relativePath: { eq: "debbie.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 810) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `
      )

      const profilePicture = data.profilePic.childImageSharp.fluid;
  
    return(
      <>
        <section style={{padding: '3rem 0'}}>
             <Container>
                <Row>
                    <Col xs={12} md={8}>
                       <div style={{backgroundColor: 'white'}}>
                           <Row style={{paddingBottom: '1rem'}}>
                               <Col>
                                <h2>Why <span style={{color: '#FB7272'}}>trust</span> Bright and Clean?</h2>
                               </Col>
                           </Row>
                           <hr style={{border: '1px solid #AED9E0', width: '10%', margin: '0px'}}/>
                           <Row style={{padding: '1rem 0'}}>
                               <Col><h3>Home and Office Cleaners</h3></Col>
                           </Row>
                           <Row style={{paddingBottom: '1rem'}} className="text-justify">
                               <Col>
                                  <p>Bright and Clean is a family run business bringing years of experience in understanding and exceeding your needs and expectations with its highly experienced team of professionals.  We build trust relationships and offer consistency by ensuring we keep our dedicated teams committed to your home or business.</p> 
                                  <p>When you’re looking for a reliable cleaning company which will take care of your home or business premises, Bright and Clean services offer you a flexible range of professional cleaning services with the perfect balance of experience, price and quality. </p> 
                               </Col>                             
                           </Row>                          
                           <Row>
                               <Col>
                               <Link to="/contact-us"><Button style={{height: '3rem'}} id="trust-cta">Get in Touch</Button></Link>
                               </Col>
                           </Row>
                       </div> 
                    </Col>
                    <Col xs={12} md={4}>                    
                           <Row>
                               <Col id="profile-picture">
                               <Img fluid={profilePicture} style={{borderRadius: '100%', objectFit: 'cover', marginTop: '1rem'}} />                                
                               </Col>
                           </Row>                           
                           <Row className="text-center" style={{paddingTop: '1rem'}}>
                               <Col><h4>Debbie Hind</h4><p >Founder of Bright and Clean</p></Col>                               
                           </Row>  
                    </Col>
                </Row>
                </Container>
            </section>
          <section className="bg-light" style={{padding: '3rem 0'}}>
            <Container>
                <Row style={{}} className="text-center">
                    <Col>
                        <h3>Why you should call us right now</h3>
                    </Col>
                </Row>
                <hr style={{border: '1px solid #AED9E0', width: '50%'}}/>
                <Row style={{paddingTop: '1em'}} >                  
                    <Col sm={12} md={6}>                      
                      <ul id="more-reasons-to-call">
                        <li>Family Run Business</li>
                        <li>Highly Experienced and personalised service</li>
                        <li>The same reliable team every time</li>
                        <li>No monthly fees, no hidden extras just a simple affordable hourly rate</li>
                        <li>We offer the convenience of various payment methods</li>
                        <li>No minimum term contracts</li>       
                      </ul>
                    </Col>
                    <Col sm={12} md={6}>
                      <ul id="more-reasons-to-call">
                      <li>Pay only for what you use</li>
                        <li>Free estimates over the phone</li>
                        <li>Reassurance with your security in safe, trusted hands</li>
                        <li>We use eco-friendly solutions and detergents on request</li>
                        <li>We ensure our cleaning teams are polite, friendly and hard-working</li>
                        <li>Our local cleaners are fully trained, vetted and insured</li>      
                        <li>Our 100% satisfaction guarantee</li>      
                      </ul>
                    </Col>
                </Row>
                <Row className="text-center" style={{paddingTop: '1rem'}}>
                    <Col>
                    <a href="tel:+447802221006"><Button variant="outline-primary" style={{height: '3rem'}} id="why-call-us-cta">Call Us</Button></a>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    )  
  
}



export default Trust
